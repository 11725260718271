import React from "react";
import PikePushupImage from "./images/PikePushupImage";
import SideSheet from "./base/SideSheet";
import { ExerciseInfoSheetData, EQUIPMENT } from "../models/cms";
import "../styles/ExerciseInfoSheet.css";

interface Props {
  title: string;
  data: ExerciseInfoSheetData;
}

function ExerciseInfoSheet(props: Props) {
  const {
    difficulty,
    progressions,
    muscleGroups,
    regressions,
    equipment,
    picture
  } = props.data;

  return (
    <SideSheet
      headingImage={
        picture ? (
          <div style={{ width: "320px" }}>
            <img alt={`Person performing ${props.title}`} src={picture.url} />
          </div>
        ) : (
          <PikePushupImage alt="Man doing calisthenics" />
        )
      }
    >
      <h2>{props.title}</h2>
      <h3>Difficulty: {difficulty}</h3>
      {progressions && progressions.length > 0 && (
        <>
          <h3>Progressions</h3>
          <p>
            Looking for your next challenge? The below exercises are more
            challenging progressions for the {props.title}.
          </p>
          <ul className="ExerciseInfoSheet__list">
            {progressions.map(progression => (
              <li key={progression}>{progression}</li>
            ))}
          </ul>
        </>
      )}
      {regressions && regressions.length > 0 && (
        <>
          <h3>Regressions</h3>
          <p>
            If the {props.title} is too challenging, prime yourself with the
            following regressions.
          </p>
          <ul className="ExerciseInfoSheet__list">
            {regressions.map(regression => (
              <li key={regression}>{regression}</li>
            ))}
          </ul>
        </>
      )}
      {equipment.length > 0 && (
        <>
          <h3>Perform with</h3>
          <p>
            You can perform the {props.title} with the any of the following
            equipment:
          </p>
          <ul>
            {equipment.map(item => {
              const { _id, content } = EQUIPMENT[item];

              return <li key={_id}>{content}</li>;
            })}
          </ul>
        </>
      )}
      {/* {workouts && workouts.length > 0 && (
        <>
          <h3>Workout routines</h3>
          <p>The {props.title} is used in the following workout routines:</p>
          <ul className="ExerciseInfoSheet__list">
            {workouts.map(workout => {
              const formattedWorkout = formatWorkout(workout);

              return (
                <li key={workout}>
                  <Link to={`/workouts/${formattedWorkout}/`}>
                    {WORKOUTS[formattedWorkout]}
                  </Link>
                </li>
              );
            })}
          </ul>
        </>
      )} */}
      {muscleGroups && muscleGroups.length > 0 && (
        <>
          <h3>Muscles worked</h3>
          <ul className="ExerciseInfoSheet__list">
            {muscleGroups.map(muscleGroup => (
              <li key={muscleGroup}>{muscleGroup.replace(/_/g, " ")}</li>
            ))}
          </ul>
        </>
      )}
    </SideSheet>
  );
}

// function formatWorkout(workout: string): Workout {
//   const lowerCaseWorkout = workout.toLowerCase();
//   const formattedWorkout = lowerCaseWorkout.replace(/_/g, "-");

//   return formattedWorkout as Workout;
// }

export default ExerciseInfoSheet;
