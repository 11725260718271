import React, { useState } from "react";
import VideoButtonGroup from "./VideoButtonGroup";
import Player from "react-player";
import { Link } from "gatsby";

interface ButtonStateInput {
  maleVideo?: string;
  femaleVideo?: string;
}

function getDefaultButtonState({ maleVideo, femaleVideo }: ButtonStateInput) {
  if (femaleVideo) {
    return "female";
  }

  if (maleVideo) {
    return "male";
  }

  return "female";
}

interface Props {
  maleVideo?: string;
  femaleVideo?: string;
}

function InstructorVideo(props: Props) {
  const { maleVideo, femaleVideo } = props;

  const disabledButtons: [boolean, boolean] = [!femaleVideo, !maleVideo];

  const defaultButtonState = getDefaultButtonState({
    maleVideo,
    femaleVideo
  });

  const [selectedGender, selectGender] = useState<"female" | "male">(
    defaultButtonState
  );

  return (
    <>
      <VideoButtonGroup
        selectedButton={selectedGender}
        handleClick={selectGender}
        disabledButtons={disabledButtons}
      />
      {!disabledButtons[0] || !disabledButtons[1] ? (
        <div style={{ margin: "0 auto" }}>
          <Player
            url={
              selectedGender === "male" ? props.maleVideo : props.femaleVideo
            }
            controls
            data-testid="video-player"
            width="100%"
            light
            playing
          />
        </div>
      ) : (
        <div>
          I'm afraid we don't have a video for this exercise yet. Have one you
          like? <Link to="/contact-us/">Let us know!</Link>
        </div>
      )}
    </>
  );
}

export default InstructorVideo;
