import React from "react";
import {
  PullUpBarAffiliateLink,
  RingsAffiliateLink,
  ParallettesAffiliateLink,
  DipStationAffiliateLink
} from "../components/affiliates";
import { Dictionary } from ".";

export interface Exercise {
  slug: string;
  title: string;
  seoTitle: string;
  description: {
    html: string;
  };
  seoDescription: string;
  execution: {
    html: string;
  };
  maleYoutubeVideo?: string;
  femaleYoutubeVideo?: string;
  faqs: FAQ[];
  picture?: {
    url: string;
  };
  difficulty: number;
  muscleGroups: string[];
  workouts: string[];
  equipment: string[];
  additionalData: AdditionalData;
}

interface FAQ {
  question: string;
  answer: string;
}

interface AdditionalData {
  progressions: string[];
  regressions: string[];
}

export interface ExerciseInfoSheetData {
  progressions: string[];
  regressions: string[];
  workouts: string[];
  picture?: {
    url: string;
  };
  difficulty: number;
  equipment: string[];
  muscleGroups: string[];
}

export type Workout =
  | "recommended-routine"
  | "first-pull-ups-and-beyond"
  | "improve-your-isometric-holds";

interface Equipment {
  _id: string;
  content: React.ReactNode;
}

export const EQUIPMENT: Dictionary<Equipment> = {
  Bodyweight: {
    _id: "bodyweight",
    content: "Bodyweight"
  },
  Pull_Up_Bar: {
    _id: "pull-up-bar",
    content: <PullUpBarAffiliateLink>Pull Up Bar</PullUpBarAffiliateLink>
  },
  Rings: {
    _id: "rings",
    content: <RingsAffiliateLink>Rings</RingsAffiliateLink>
  },
  Parallettes: {
    _id: "parallettes",
    content: <ParallettesAffiliateLink>Parallettes</ParallettesAffiliateLink>
  },
  Straight_Bar: {
    _id: "straight_bar",
    content: <DipStationAffiliateLink>Straight Bar</DipStationAffiliateLink>
  },
  Parallel_Bars: {
    _id: "parallel_bars",
    content: <DipStationAffiliateLink>Parallel Bars</DipStationAffiliateLink>
  }
};
