import React from "react";
import HSeparator from "../HSeparator";
import "../../styles/TextPageLayout.css";
import PageContent from "./PageContent";
import Footer from "../Footer";
import ExerciseInfoSheet from "../ExerciseInfoSheet";
import { ExerciseInfoSheetData } from "../../models/cms";
import NavRoutes from "../NavRoutes";

interface Props {
  title: string;
  children: React.ReactNode;
  sideSheetData: ExerciseInfoSheetData;
}

function ExercisePageLayout(props: Props) {
  const { children, title, sideSheetData } = props;

  return (
    <>
      <NavRoutes />
      <div className="TextPageLayout">
        <HSeparator />
        <PageContent pageTitle={title} hasSideSheet>
          {children}
          <ExerciseInfoSheet title={title} data={sideSheetData} />
        </PageContent>
      </div>
      <Footer hasSideSheet />
    </>
  );
}

export default ExercisePageLayout;
