import React, { useContext, useEffect } from "react";
import classnames from "classnames";
import SheetContext from "../../context/SheetContext";
import OutsideClickHandler from "../OutsideClickHandler";
import "../../styles/SideSheet.css";
import Icon from "../Icon";
import { CloseIcon } from "../../icons";
import { useWindowSize, useLockBodyScroll, useToggle } from "react-use";
import { globalHistory } from "@reach/router";

interface Props {
  headingImage?: React.ReactNode;
  children: React.ReactNode;
}

function SideSheet({ children, headingImage }: Props) {
  const { isSheetVisible, setSheetVisibility } = useContext(SheetContext);
  const { width } = useWindowSize();
  const [contentScrollLock, setContentScrollLock] = useToggle(false);

  useEffect(() => {
    return globalHistory.listen(() => {
      if (isSheetVisible) {
        setSheetVisibility(false);
      }
    });
  }, [isSheetVisible, setSheetVisibility]);

  useLockBodyScroll(contentScrollLock);

  return (
    <div
      onPointerEnter={() => setContentScrollLock(true)}
      onPointerLeave={() => setContentScrollLock(false)}
    >
      <OutsideClickHandler onOutsideClick={() => setSheetVisibility(false)}>
        <aside
          data-testid="side-sheet"
          className={classnames("SideSheet", {
            "SideSheet--active": isSheetVisible
          })}
        >
          {width < 900 && (
            <div className="SideSheet__icon-width-container">
              <div className="SideSheet__icon-position-container">
                <Icon
                  containerWidth={24}
                  title="close side sheet."
                  src={CloseIcon}
                  handleClick={() => setSheetVisibility(false)}
                />
              </div>
            </div>
          )}
          {headingImage}
          <div className="SideSheet__text-content">{children}</div>
        </aside>
      </OutsideClickHandler>
    </div>
  );
}

export default SideSheet;
