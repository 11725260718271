import React from "react";
import classnames from "classnames";
import "../../styles/ButtonGroup.css";
import Icon from "../Icon";

interface Button {
  id: string;
  text: string;
  icon?: string;
}

interface Props {
  buttonOne: Button;
  buttonTwo: Button;
  selectedButton: string | null;
  handleClick: (val: string) => void;
  disabledButtons: [boolean, boolean];
  size?: "standard" | "small";
}

function ButtonGroup(props: Props) {
  const {
    buttonOne,
    buttonTwo,
    handleClick,
    selectedButton,
    disabledButtons,
    size = "standard"
  } = props;

  const areBothButtonsDisabled = disabledButtons[0] && disabledButtons[1];

  return (
    <div className={`ButtonGroup__container ButtonGroup__container--${size}`}>
      <Button
        text={buttonOne.text}
        id={buttonOne.id}
        handleClick={handleClick}
        icon={buttonOne.icon}
        isSelected={selectedButton === buttonOne.id}
        isDisabled={areBothButtonsDisabled || disabledButtons[0]}
        size={size}
      />
      <Button
        text={buttonTwo.text}
        id={buttonTwo.id}
        handleClick={handleClick}
        icon={buttonTwo.icon}
        isSelected={selectedButton === buttonTwo.id}
        isDisabled={areBothButtonsDisabled || disabledButtons[1]}
        size={size}
      />
    </div>
  );
}

interface ButtonProps {
  isDisabled: boolean;
  id: string;
  text: string;
  handleClick: (id: string) => void;
  isSelected: boolean;
  size: "standard" | "small";
  icon?: string;
}

function Button(props: ButtonProps) {
  const { text, size, icon, id, isSelected, handleClick, isDisabled } = props;

  return (
    <button
      data-testid={`button-group-${text}`}
      onClick={() => {
        if (isDisabled) {
          return null;
        }

        return handleClick(id);
      }}
      className={classnames(
        `ButtonGroup__button ButtonGroup__button--${size} hover-sheet`,
        {
          "ButtonGroup__button--selected": isSelected,
          "ButtonGroup__button--disabled": isDisabled
        }
      )}
    >
      {icon && <Icon containerWidth={40} title={`${text} icon`} src={icon} />}
      <p className={`ButtonGroup__text ButtonGroup__text--${size}`}>{text}</p>
    </button>
  );
}

export default ButtonGroup;
