import React, { useRef, useEffect } from "react";

interface OutsideClickHandlerProps {
  children: React.ReactNode;
  onOutsideClick: () => void;
}

function OutsideClickHandler({
  children,
  onOutsideClick
}: OutsideClickHandlerProps) {
  const childNodeRef = useRef<HTMLDivElement>(null);

  function handleClick(e: MouseEvent) {
     const isDescendant =
      childNodeRef.current && childNodeRef.current.contains(e.target as Node);

    if (isDescendant) return null;

    return onOutsideClick();
  }

  useEffect(() => {
    window.addEventListener("mousedown", handleClick, { passive: true });

    return function cleanup() {
      window.removeEventListener("mousedown", handleClick);
    };
    // eslint-disable-next-line
  }, []);

  return <div ref={childNodeRef}>{children}</div>;
}

export default OutsideClickHandler;
