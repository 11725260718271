import React from "react";
import ButtonGroup from "./base/ButtonGroup";
import { FemaleSymbolIcon, MaleSymbolIcon } from "../icons";

interface Props {
  selectedButton: "female" | "male";
  handleClick: (value: "female" | "male") => void;
  disabledButtons: [boolean, boolean];
}

function VideoButtonGroup({
  handleClick,
  selectedButton,
  disabledButtons
}: Props) {
  return (
    <ButtonGroup
      buttonOne={{ id: "female", text: "female", icon: FemaleSymbolIcon }}
      buttonTwo={{ id: "male", text: "male", icon: MaleSymbolIcon }}
      handleClick={handleClick as (value: string) => void}
      selectedButton={selectedButton}
      disabledButtons={disabledButtons}
    />
  );
}

export default VideoButtonGroup;
