import React from "react";
import { Link } from "gatsby";
import { graphql } from "gatsby";
import ExercisePageLayout from "../components/layouts/ExercisePageLayout";
import { Exercise } from "../models/cms";
import "../styles/ExerciseTemplate.css";
import InstructorVideo from "../components/InstructorVideo";
import AccordionGroup from "../components/base/AccordionGroup";
import SEO from "../components/SEO";

interface Data {
  calisthenicsSkills: {
    exercise: Exercise;
  };
}

export interface Props {
  data: Data;
  path: string;
}

function ExerciseTemplate(props: Props) {
  const { exercise } = props.data.calisthenicsSkills;

  const sideSheetData = {
    ...exercise.additionalData,
    picture: exercise.picture,
    difficulty: exercise.difficulty,
    workouts: exercise.workouts,
    muscleGroups: exercise.muscleGroups,
    equipment: exercise.equipment
  };

  return (
    <ExercisePageLayout title={exercise.title} sideSheetData={sideSheetData}>
      <SEO
        path={props.path}
        title={exercise.seoTitle}
        description={exercise.seoDescription}
      />
      <section className="Exercise__section">
        <h3>What is the {exercise.title}?</h3>
        <div
          className="Exercise__paragraph"
          dangerouslySetInnerHTML={{ __html: exercise.description.html }}
        />
      </section>
      <section className="Exercise__section">
        <h3>{exercise.title} demonstration</h3>
        <p>Choose the preferred gender for your instructor:</p>
        <InstructorVideo
          femaleVideo={exercise.femaleYoutubeVideo}
          maleVideo={exercise.maleYoutubeVideo}
        />
      </section>
      <section className="Exercise__section">
        <h3>How to perform the {exercise.title}</h3>
        <div
          className="Exercise__paragraph"
          dangerouslySetInnerHTML={{ __html: exercise.execution.html }}
        />
      </section>
      {exercise.faqs && exercise.faqs.length > 0 && (
        <section className="Exercise__section">
          <h3>FAQs</h3>
          <AccordionGroup
            accordionData={exercise.faqs.map((faq, i) => ({
              title: faq.question,
              content: <p dangerouslySetInnerHTML={{ __html: faq.answer }} />,
              key: i
            }))}
          />
        </section>
      )}
      <section>
        <h4>
          Back to the <Link to="/exercises/">Exercise List</Link>
        </h4>
      </section>
    </ExercisePageLayout>
  );
}

export default ExerciseTemplate;

export const query = graphql`
  query($slug: String!) {
    calisthenicsSkills {
      exercise(where: { slug: $slug }) {
        title
        slug
        seoDescription
        seoTitle
        execution {
          html
        }
        description {
          html
        }
        difficulty
        picture {
          url
        }
        muscleGroups
        workouts
        maleYoutubeVideo
        femaleYoutubeVideo
        faqs
        equipment
        additionalData
      }
    }
  }
`;
